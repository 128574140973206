var loading = false;
var timer = null;

const toggleLoading = () => {
  document.getElementById("spinner").style.display = loading ? "none" : "flex";
  document.getElementById("go").style.display = loading ? "block" : "none";
  loading = !loading;
  document.getElementById("button").disabled = loading;
};

const req = async endpoint => {
  let options = {
    method: "GET"
  };
  let response = await fetch(endpoint, options).catch(err => {
    return Promise.reject(err);
  });
  let jsonResponse = await response.json().catch(err => {
    return Promise.reject(err);
  });
  if (!response || !response.ok) return Promise.reject(jsonResponse || false);
  return jsonResponse;
};

const toggleError = (error = null) => {
  if (!error) {
    document.getElementById("error").style.display = "none";
    return;
  }
  document.getElementById("error").innerHTML = error;
  document.getElementById("error").style.display = "block";
};

const startTimer = (time, res, host) => {
  timer = setInterval(() => {
    time--;
    document.getElementById("timer").innerHTML = time;
    if (time === 0) {
      clearInterval(timer);
      sendRequest();
    }
  }, 1000);
};

window.copyToClipboard = val => {
  var el = document.createElement("textarea"); // Create a <textarea> element
  el.value = val; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  var selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

const getClipboard = copyWhat => {
  return `<svg class="clipboard" onclick="copyToClipboard('${copyWhat}')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>`;
};

const generateTemplate = (keys, obj, index) => {
  let template = `<div class="entry"><div class="space-between center-vert row"><div class="def-row center-vert"><p><b>Host:</b> ${
    obj.host
  }</p>${getClipboard(obj.host)}</div><p class="no">${index}</p></div>`;
  for (let i = 0; i < keys.length; i++) {
    if (!obj[keys[i].toLowerCase()]) continue;
    let val = obj[keys[i].toLowerCase()];
    keys[i] = keys[i].replace("-", " ");
    template += `<div class="def-row center-vert"><p><b>${
      keys[i]
    }: </b>${val}</p>${getClipboard(val)}</div>`;
  }
  template += "</div>";
  return template;
};

const processResponse = (res, host) => {
  document.getElementById("display-section").style.display = "none";
  document.getElementById("entries").innerHTML = "";
  if (!res || !Array.isArray(res) || res.error) {
    let msg = res.error || "An unknown error occured. Try again?";
    console.error(msg);
    toggleError(msg);
    return;
  }
  document.getElementById("display-section").style.display = "block";
  document.getElementById("hostn").innerHTML = host;
  let multiple = false;
  if (res.length > 1) {
    multiple = true;
    document.getElementById("multiple").style.display = "block";
  } else {
    document.getElementById("multiple").style.display = "none";
  }
  let timer = parseInt(res[0].ttl);
  document.getElementById("timer").innerHTML = timer || 300;
  startTimer(timer, res, host);

  for (let i = 0; i < res.length; i++) {
    let entry = res[i];
    let index = multiple ? i + 1 : "";

    document.getElementById("entries").innerHTML += generateTemplate(
      [
        "IP",
        "IPV6",
        "Target",
        "TXT",
        "Serial",
        "MNAME",
        "RNAME",
        "Refresh",
        "Retry",
        "Expiry",
        "Minimum-TTL",
        "TTL"
      ],
      entry,
      index
    );
    if (i + 1 != res.length) {
      document.getElementById("entries").innerHTML += "<hr>";
    }
  }
};

window.addEventListener("DOMContentLoaded", e => {
  document.getElementById("form").addEventListener("submit", e => {
    e.preventDefault();
    sendRequest();
  });
});

const sendRequest = () => {
  if (loading) return;
  clearInterval(timer);
  toggleError();
  toggleLoading();
  let host = document.getElementById("host").value;
  let type = document.getElementById("type").value;
  let endpoint = `https://dns.oxro.io/api?host=${host}&type=${type}`;
  req(endpoint)
    .then(res => {
      toggleLoading();
      processResponse(res, host);
    })
    .catch(err => {
      toggleLoading();
      console.error(err.error);
      toggleError(err.error || "An unknown error occured. Try again?");
      document.getElementById("display-section").style.display = "none";
    });
};

// let template = `<div class="entry"><div class="row space-between center-vert"><p><b>Host:</b> ${host}</p><p class="no">${index}</p></div><p><b>IP:</b> ${ip}</p><p><b>TTL:</b> ${ttl}</p></div>`;
